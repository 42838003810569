import { useRouter } from 'next/router';
import { i18n } from 'next-i18next.config';
import { useEffect, useState } from 'react';

import { originalLocaleFormat } from '@lib/locales';
import { logger } from '@lib/logger';

interface TurnToConfigProps {
  locale?: string;
  pageId: string;
  schemaOrgEntityType?: string;
  sku?: string;
  embed?: boolean;
  chatter?: any;
  title?: any;
}

const removeTurnToScript = () => {
  const configScript = document.getElementById('turnto-config');
  const turnToScript = document.getElementById('turnto-script');
  if (configScript && turnToScript && (window as any).TurnToCmd) {
    delete (window as any).TurnToCmd;
    configScript.remove();
    turnToScript.remove();
  }
};

const useTurnTo = (config: TurnToConfigProps, pathname?: string) => {
  const siteKey = process.env.NEXT_PUBLIC_TURNTO_SITEKEY;

  const [init, setInit] = useState<boolean>(false);

  const { locale } = useRouter();
  const formattedLocale = originalLocaleFormat(locale || i18n.defaultLocale).replace('-', '_');

  useEffect(() => {
    if (typeof window !== 'undefined' && siteKey) {
      // Check if Turnto is already loaded
      if (!document.getElementById('turnto-script') && !(window as any).TurnToCmd) {
        const configScript = document.createElement('script');
        configScript.id = 'turnto-config';
        configScript.innerHTML = `
                    var turnToConfig = {
                        locale: '${config.locale || formattedLocale || ''}',
                        pageId: '${config.pageId || ''}'
                    };
                    window.TurnToCmd=window.TurnToCmd||function(){
                        (TurnToCmd.q=TurnToCmd.q||[]).push(arguments)
                    };
                `;

        document.head.appendChild(configScript);

        // Add main TurnTo Script
        const script = document.createElement('script');
        script.id = 'turnto-script';
        script.src = `https://widgets.turnto.com/v5/widgets/${siteKey}/js/turnto.js`;
        script.async = true;
        script.onerror = (e) => {
          logger.error('Error loading TurnTo script', e);
        };
        document.head.appendChild(script);
        setInit(true);
      } else {
        (window as any).turnToConfig = {
          ...(window as any).turnToConfig,
          ...config,
          locale: config.locale ?? formattedLocale,
        };
        setInit(true);
      }
    }
  }, [config, siteKey, pathname, formattedLocale]);

  useEffect(() => {
    return () => {
      removeTurnToScript();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendFeed = (data: Record<string, any>) => {
    if (!init) {
      logger.error('script is not loaded yet. Feed not sent.');
      return;
    }
    (window as any).TurnToCmd('feed.send', data);
  };

  return { init, sendFeed };
};
export default useTurnTo;
