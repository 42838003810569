import { Experience as ExperienceComponent } from '@ninetailed/experience.js-next';
import isEmpty from 'lodash/isEmpty';

import Layout from '@components/common/Layout/Layout';
import { mapScreenExperiences } from '@components/screen/mapExperience';
import ScreenComponent, { Props as ScreenProps } from '@components/screen/screen';

export { getServerSideProps } from '@data-fetch/generic-screen';

export default function Screen(props: ScreenProps) {
  const { page, currencyCode, locale } = props;
  const screenExperiences = mapScreenExperiences(page?.nt_experiences);
  if (isEmpty(screenExperiences)) {
    return <ScreenComponent {...props} />;
  }

  return (
    <ExperienceComponent
      id={page.id}
      experiences={screenExperiences}
      component={ScreenComponent}
      page={page}
      passthroughProps={{
        currencyCode,
        locale,
        baseline: page,
      }}
    />
  );
}

Screen.Layout = Layout;
